import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ITeamMember } from 'types/teamMember';
import './styles.scss';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  data: ITeamMember | Record<string, never>;
}

export const TeamMemberModal = ({ isOpen, onClose, data }: IProps) => {
  return (
    <Modal
      className="team-member-modal"
      show={isOpen}
      onHide={onClose}
      backdropClassName="backdrop-light"
      centered
    >
      <Modal.Body>
        <button onClick={onClose} className="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <Modal.Title className="title">{data.name}</Modal.Title>
        <div className="subtitle">Тренер</div>
        <div className="text">{data.text}</div>
      </Modal.Body>
    </Modal>
  );
};
