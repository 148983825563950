import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LocationContext } from 'containers/Location/LocationContext';
import Form from './Form';
import messages from './messages';
import { FormImg } from './FormImg';

interface IProps {
  stopHookTimer: () => void;
}

export const FormBlock = ({ stopHookTimer }: IProps) => {
  const location = React.useContext(LocationContext);

  return (
    <section className="form-block" id="form-section">
      <FormImg />
      <div className="form-content">
        <p className="description">
          <FormattedMessage {...messages.description} />
        </p>
        <h5 className="title">
          Запишитесь на <b>бесплатное</b> пробное занятие
        </h5>
        <Form stopHookTimer={stopHookTimer} location={location} />
      </div>
    </section>
  );
};
