import { FormikErrors } from 'formik';
import { AxiosError } from 'axios';

import { IError } from './types';
import { parseErrors } from '../utils/errors';

export const apiCallSuccessWithMessage = (
  setSubmitting: (v: boolean) => void,
  callback?: () => void,
) => () => {
  setSubmitting(false);
  if (callback) {
    callback();
  }
};

export const apiCallErrorWithMessage = (
  setSubmitting: (v: boolean) => void,
  setErrors: (v: FormikErrors<unknown>) => void | undefined,
  setStatus: (status?: string) => void,
  callback?: () => void,
) => (error: AxiosError<IError>) => {
  const errorData = error.response?.data;
  let message;
  // TODO: replace with error_code to message map in future
  if (errorData?.message !== 'The given data was invalid.') {
    message = 'Что-то пошло не так, попробуйте повторить запрос чуть позже';
  } else {
    message = 'Проверьте правильность введённых данных';
  }
  if (setErrors && errorData?.errors) {
    setErrors(parseErrors(errorData.errors));
  }
  setSubmitting(false);
  setStatus(message);
  if (callback) {
    callback();
  }
};
