import styled from '@emotion/styled';
import React from 'react';
import { useVisible } from 'components/AppearSensor/useVisible';
import cx from 'classnames';

interface IProps {
  children: React.ReactNode;
  animation: string;
  delay: number;
  tag: keyof JSX.IntrinsicElements;
  duration: number;
  className?: string;
  alt?: string;
  src?: string;
  visible: boolean;
}

interface IComponentProps {
  visible: boolean;
  animated: boolean;
  delay: number;
  keyframes: string;
  duration: number;
  as: string;
}

const Component = styled.div<IComponentProps>`
  opacity: ${(props) => (props.visible ? 1 : 0)};

  &.animated {
    animation-name: ${(props) => props.keyframes};
    animation-duration: ${(props) => props.duration}ms;
    animation-fill-mode: both;
    animation-delay: ${(props) => props.delay}ms;
  }
`;

export const Animation = ({
  animation,
  className,
  children,
  tag,
  ...props
}: IProps) => {
  const visible = useVisible();
  return (
    <Component
      as={tag}
      keyframes={animation}
      animated={visible}
      className={cx(className, { animated: visible })}
      {...props}
    >
      {children}
    </Component>
  );
};

Animation.defaultProps = {
  duration: 1000,
  tag: 'div',
  visible: false,
};
