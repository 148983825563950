import { CourseIds } from 'constants/courses';

export enum SocialType {
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
}

export interface IReview {
  courseId: CourseIds;
  name: string;
  text: string;
  social: SocialType;
  socialLink?: string;
  socialTag?: string;
  photo: string;
}
