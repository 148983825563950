import React from 'react';
import styled from '@emotion/styled';

import Image from 'components/Image';
import checkImg from 'assets/images/check.png';

import { SCREEN_BREAKPOINTS } from '../../../constants/theme';

const CheckImg = styled(Image)`
  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    height: 29px;
    width: auto;
  }
`;

const Text = styled.p`
  margin: 0;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    font-size: 13px;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 40px;
  margin-top: 40px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.md}px) {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    margin-bottom: 14px;
    margin-top: 14px;
  }
`;

const Point = ({ children }) => (
  <Wrapper className="d-flex align-items-start">
    <CheckImg className="mr-3" src={checkImg} lazyload alt="галочка" />
    <Text>{children}</Text>
  </Wrapper>
);

export default Point;
