import React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { graphql, StaticQuery } from 'gatsby';

export const BackgroundImg = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          coverImage: file(
            relativePath: { eq: "screens/Home/MainBlock/images/boy.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                blurredOptions: { width: 200 }
                webpOptions: { quality: 100 }
              )
            }
          }
        }
      `}
      render={(data) => {
        const imageData = getImage(data.coverImage) as IGatsbyImageData;
        return (
          <>
            <div className="background-main-container">
              <GatsbyImage image={imageData} alt="Plant" loading="eager" />
            </div>
            <div className="line">
              <div className="line-img" />
            </div>
          </>
        );
      }}
    />
  );
};
