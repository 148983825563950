import styled from '@emotion/styled';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FormattedMessage } from 'react-intl';

import Image from 'components/Image';
import boyImg from 'assets/images/boy3.png';

import { SCREEN_BREAKPOINTS } from 'constants/theme';
import Point from './Point';
import messages from './messages';
import { AppearSensor } from '../../../components/AppearSensor';
import { Animation } from '../../../components/Animation';
import { Appear } from './animation';

const Section = styled.section`
  margin-bottom: 180px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    margin-bottom: 120px;
  }
`;

const Title = styled.h3`
  font-size: 34px;
  text-align: center;
  margin-bottom: 76px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.lg}px) {
    margin-bottom: 80px;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.md}px) {
    margin-right: 80px;
    margin-left: 80px;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    margin-bottom: 50px;
    font-size: 20px;
    margin-right: 40px;
    margin-left: 40px;
  }
`;

const Img = styled(Image)`
  width: 100%;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.lg}px) {
    height: 400px;
    width: auto;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    height: 264px;
    width: auto;
  }
`;

const Block5 = () => (
  <Section>
    <Title>
      <FormattedMessage {...messages.title} />
    </Title>
    <AppearSensor>
      <Row>
        <Col
          lg="4"
          className="d-flex flex-column justify-content-center mb-3 mb-lg-0"
        >
          <Animation animation={Appear} delay={400} duration={500}>
            <Point>
              <FormattedMessage {...messages.point1} />
            </Point>
          </Animation>
          <Animation animation={Appear} delay={800} duration={500}>
            <Point>
              <FormattedMessage {...messages.point2} />
            </Point>
          </Animation>
        </Col>
        <Col lg="4" className="text-center">
          <Img src={boyImg} lazyload alt="ребенок придумал ход" />
        </Col>
        <Col lg="4" className="d-flex flex-column justify-content-center">
          <Animation animation={Appear} delay={1200} duration={500}>
            <Point>
              <FormattedMessage {...messages.point3} />
            </Point>
          </Animation>
          <Animation animation={Appear} delay={1600} duration={500}>
            <Point>
              <FormattedMessage {...messages.point4} />
            </Point>
          </Animation>
        </Col>
      </Row>
    </AppearSensor>
  </Section>
);

export default Block5;
