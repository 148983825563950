import React from 'react';
import styled from '@emotion/styled';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FormattedMessage } from 'react-intl';

import Image from 'components/Image';
import boy2Img from 'assets/images/boy2.png';

import { SCREEN_BREAKPOINTS } from 'constants/theme';
import Reasons from './Reasons';
import messages from './messages';

const Title = styled.h2`
  font-size: 34px;
  text-align: center;
  margin-bottom: 100px;

  @media all and (max-width: 1240px) {
    margin-bottom: 40px;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    margin-bottom: 25px;
    font-size: 20px;
  }
`;

const Section = styled.section`
  margin-bottom: 120px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.lg}px) {
    margin-bottom: 160px;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    margin-bottom: 100px;
  }
`;

const BoyImg = styled(Image)`
  width: 100%;
  margin-right: 50px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.lg}px) {
    margin-right: 0;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    margin-bottom: 30px;
  }
`;

export const BenefitsBlock = () => (
  <Section className="benefits-block">
    <Title>
      <FormattedMessage {...messages.title} />
    </Title>
    <Row className="justify-content-center">
      <Col xl="5" lg="6" sm="8" xs="10" className="d-flex align-items-center">
        <BoyImg
          lazyload
          src={boy2Img}
          alt="ребенок думает над ходом в шахматах"
        />
      </Col>
      <Col lg="7">
        <Reasons />
      </Col>
    </Row>
  </Section>
);
