import { keyframes } from '@emotion/core';

export const Appear = keyframes`
  0% {
    transform: scale(0.01);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;
