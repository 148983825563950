import React from 'react';
import { Member } from './Member';
import { members } from './constants';
import { TeamMemberModal } from 'containers/Modals/TeamMemberModal';

export const TeamBlock = () => {
  const [memberModal, setMemberModal] = React.useState({
    open: false,
    data: {},
  });
  const onMemberClose = React.useCallback(() => {
    setMemberModal({ open: false, data: memberModal.data });
    // modal close animation text blinking fix
    setTimeout(() => {
      setMemberModal({ open: false, data: {} });
    }, 50);
  }, [memberModal.data]);
  const onMemberOpen = React.useCallback((data) => {
    setMemberModal({ open: true, data });
  }, []);

  return (
    <section className="team-block">
      <h2 className="title">Наши ведущие преподаватели</h2>
      <p className="subtitle">
        Кликните на фотографию, чтобы прочитать описание
      </p>
      <div className="team-members-row row justify-content-center">
        {members.map((member, index) => (
          <div key={index} className="team-col col-lg-6">
            <Member onExpand={onMemberOpen} data={member} />
          </div>
        ))}
      </div>
      <TeamMemberModal
        isOpen={memberModal.open}
        data={memberModal.data}
        onClose={onMemberClose}
      />
    </section>
  );
};
