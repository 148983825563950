import React from 'react';
import backgroundImg from './images/background.png';

export const WorldCoverageBlock = () => (
  <section className="world-coverage-block">
    <img
      className="world-coverage-image"
      placeholder="none"
      loading="lazy"
      src={backgroundImg}
      alt="Карта со странами наших учеников"
    />
  </section>
);
