import React from 'react';
import chessBoardImg from './images/chess-board.png';

export const ChessBoardItem = () => (
  <div className="chess-board-item container">
    <img
      src={chessBoardImg}
      alt="шахматная доска"
      className="chess-board-image"
    />
  </div>
);
