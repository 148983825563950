import React from 'react';
import chessFigureImg from './images/chess-figures.png';

export const ChessFiguresItem = () => (
  <div className="chess-figures-item container">
    <img
      className="chess-figures-image"
      src={chessFigureImg}
      alt="шахматные фигуры"
    />
  </div>
);
