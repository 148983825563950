import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { AppearContext } from './AppearContext';

interface IProps {
  children: React.ReactNode;
  offset?: number;
}

export const AppearSensor = ({ children, offset }: IProps) => {
  const [visible, setVisible] = React.useState(false);

  const handleChange = React.useCallback((state) => {
    if (state) {
      setVisible(true);
    }
  }, []);

  return (
    <VisibilitySensor
      offset={{ top: offset }}
      partialVisibility
      onChange={handleChange}
    >
      <AppearContext.Provider value={visible}>
        {children}
      </AppearContext.Provider>
    </VisibilitySensor>
  );
};
