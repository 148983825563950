import { defineMessages } from 'react-intl';

const scope = 'pages.Landing.Block3';

export default {
  ...defineMessages({
    title: {
      id: `${scope}.title`,
      defaultMessage: 'Преимущество занятий шахматами онлайн',
    },
  }),
  benefit1: defineMessages({
    title: {
      id: `${scope}.benefit1.title`,
      defaultMessage: 'Индивидуальная программа',
    },
    text: {
      id: `${scope}.benefit1.text`,
      defaultMessage:
        'Тренер составляет программу основываясь на навыках и способностях вашего ребенка таким образом, чтобы занятия проходили максимально эффективно, а также приносили ребенку удовольствие.',
    },
  }),
  benefit2: defineMessages({
    title: {
      id: `${scope}.benefit2.title`,
      defaultMessage: 'Индивидуальный график',
    },
    text: {
      id: `${scope}.benefit2.text`,
      defaultMessage: `Вы проходите программу обучения шахматам быстрее по
                индивидуальному графику, в удобном для Вас темпе`,
    },
  }),
  benefit3: defineMessages({
    title: {
      id: `${scope}.benefit3.title`,
      defaultMessage: 'Высокая эффективность занятий',
    },
    text: {
      id: `${scope}.benefit3.text`,
      defaultMessage:
        'Формат наших занятий позволяет ученику постоянно находиться на связи с преподавателем и в то же время проявлять самостоятельность в решении задач. Это позволяет быстро достичь желаемого результата.',
    },
  }),
};
