import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

import Image from 'components/Image';
import strategyImg from 'assets/images/strategy.png';
import calendarImg from 'assets/images/calendar-big.png';
import startupImg from 'assets/images/startup.png';

import { SCREEN_BREAKPOINTS } from 'constants/theme';
import { AppearSensor } from 'components/AppearSensor';
import { Animation } from 'components/Animation';
import messages from './messages';
import { Appear } from './animation';

const Img = styled(Image)`
  margin-bottom: 25px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    height: 80px;
  }
`;

const Heading = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
  text-transform: uppercase;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    font-size: 15px;
  }
`;

const Text = styled.p`
  margin-bottom: 50px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    font-size: 12px;
  }
`;

const Benefits = () => (
  <AppearSensor offset={100}>
    <Row className="justify-content-center">
      <Animation
        as={Col}
        sm="11"
        lg="4"
        className="text-center"
        animation={Appear}
        delay={200}
        duration={600}
      >
        <Img src={strategyImg} lazyload alt="удачный ход" />
        <Heading>
          <FormattedMessage {...messages.benefit1.title} />
        </Heading>
        <Text>
          <FormattedMessage {...messages.benefit1.text} />
        </Text>
      </Animation>
      <Animation
        as={Col}
        sm="11"
        lg="4"
        className="text-center"
        animation={Appear}
        delay={600}
        duration={600}
      >
        <Img src={calendarImg} lazyload alt="график" />
        <Heading>
          <FormattedMessage {...messages.benefit2.title} />
        </Heading>
        <Text>
          <FormattedMessage {...messages.benefit2.text} />
        </Text>
      </Animation>
      <Animation
        as={Col}
        sm="11"
        lg="4"
        className="text-center"
        animation={Appear}
        delay={1000}
        duration={600}
      >
        <Img src={startupImg} lazyload alt="ракета" />
        <Heading>
          <FormattedMessage {...messages.benefit3.title} />
        </Heading>
        <Text>
          <FormattedMessage {...messages.benefit3.text} />
        </Text>
      </Animation>
    </Row>
  </AppearSensor>
);

export default Benefits;
