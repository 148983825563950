import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { SCREEN_BREAKPOINTS } from 'constants/theme';
import messages from './messages';
import { AppearSensor } from 'components/AppearSensor';
import { Animation } from 'components/Animation';
import { Pop } from './animation';

const Heading = styled.h3`
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-bottom: 0;
  text-transform: uppercase;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    font-size: 15px;
  }
`;

const Number = styled.b`
  font-size: 72px;
  margin-right: 15px;

  color: ${(props) => props.color};

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    font-size: 60px;
    line-height: 60px;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 31px;
`;

const Description = styled.p`
  font-size: 15px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    font-size: 12px;
  }
`;

const Reasons = () => (
  <AppearSensor offset={200}>
    <Wrapper>
      <Heading>
        <Animation visible animation={Pop} delay={200} duration={600}>
          <Number color="#e02053">1</Number>
        </Animation>
        <FormattedMessage {...messages.reason1.title} />
      </Heading>
      <Description>
        <FormattedMessage {...messages.reason1.text} />
      </Description>
    </Wrapper>
    <Wrapper>
      <Heading>
        <Animation visible animation={Pop} delay={600} duration={600}>
          <Number color="#06a2cc">2</Number>
        </Animation>
        <FormattedMessage {...messages.reason2.title} />
      </Heading>
      <Description>
        <FormattedMessage {...messages.reason2.text} />
      </Description>
    </Wrapper>
    <Wrapper>
      <Heading>
        <Animation visible animation={Pop} delay={1000} duration={600}>
          <Number color="#f79906">3</Number>
        </Animation>
        <FormattedMessage {...messages.reason3.title} />
      </Heading>
      <Description>
        <FormattedMessage {...messages.reason3.text} />
      </Description>
    </Wrapper>
    <Wrapper>
      <Heading>
        <Animation visible animation={Pop} delay={1400} duration={600}>
          <Number color="#e02053">4</Number>
        </Animation>
        <FormattedMessage {...messages.reason4.title} />
      </Heading>
      <Description>
        <FormattedMessage {...messages.reason4.text} />
      </Description>
    </Wrapper>
  </AppearSensor>
);

export default Reasons;
