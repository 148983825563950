import { defineMessages } from 'react-intl';

const scope = 'pages.Landing.Block1';

export default {
  mainProposition: defineMessages({
    title: {
      id: `${scope}.mainProposition.title`,
      defaultMessage: 'Научим ваших детей игре в шахматы не выходя из дома',
    },
    subtitle: {
      id: `${scope}.mainProposition.subtitle`,
      defaultMessage: 'По программе комфортного развития Вашего ребёнка',
    },
  }),
  benefits: defineMessages({
    first: {
      id: `${scope}.benefits.first`,
      defaultMessage: 'от 6 до 16 лет',
    },
    second: {
      id: `${scope}.benefits.second`,
      defaultMessage: 'с любым уровнем подготовки',
    },
    third: {
      id: `${scope}.benefits.third`,
      defaultMessage: 'в удобное время',
    },
  }),
};
