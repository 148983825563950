import React from 'react';
import classNames from 'classnames';

const Image = ({
  lazyload,
  // src,
  className,
  alt,
  ...props
}) => {
  const options = {
    // [lazyload ? 'data-src' : 'src']: src,
  };

  return (
    <img
      className={classNames(
        className,
        // lazyload && 'lazyload',
      )}
      alt={alt}
      {...props}
      {...options}
    />
  );
};

export default Image;
