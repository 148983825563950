import React from 'react';
import { ITeamMember } from 'types/teamMember';

interface IProps {
  data: ITeamMember;
  onExpand: (data: ITeamMember) => void;
}

export const Member = ({ data, onExpand }: IProps) => {
  const handleExpand = React.useCallback(() => {
    onExpand(data);
  }, []);

  return (
    <div className="team-member">
      <div
        tabIndex={-1}
        className="team-member-btn"
        onClick={handleExpand}
        onKeyDown={handleExpand}
        role="button"
      >
        <img src={data.photo} alt="Фото преподавателя" />
      </div>
      <p className="name">{data.name}</p>
    </div>
  );
};
