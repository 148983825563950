import React from 'react';
import { Carousel } from 'components/Carousel';
import { PriceSwitch } from 'components/PriceSwitch';
import { usePrice } from 'utils/hooks/usePrice';
import { PriceRow } from './PriceRow';
import arrowLeftImg from './images/arrow_left.inline.svg';
import arrowRightImg from './images/arrow_right.inline.svg';

interface IProps {
  onLeadModalOpen: () => void;
}

export const PriceBlock = ({ onLeadModalOpen }: IProps) => {
  const price = usePrice();
  return (
    <section className="price-block">
      <h2 className="title">Стоимость обучения</h2>
      <Carousel
        className="price-carousel"
        controllerImgLeft={arrowLeftImg}
        controllerImgRight={arrowRightImg}
        defaultActiveIndex={0}
        header={(activeIndex, handleSwitch) => (
          <PriceSwitch activeIndex={activeIndex} onClick={handleSwitch} />
        )}
      >
        <PriceRow
          variant="primary"
          currency={price.currencyCode}
          pack={price.packages[0]}
          onActionBtnClick={onLeadModalOpen}
        />
        <PriceRow
          variant="danger"
          currency={price.currencyCode}
          pack={price.packages[1]}
          onActionBtnClick={onLeadModalOpen}
        />
        <PriceRow
          variant="secondary"
          currency={price.currencyCode}
          pack={price.packages[2]}
          onActionBtnClick={onLeadModalOpen}
        />
      </Carousel>
    </section>
  );
};
