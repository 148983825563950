import { defineMessages } from 'react-intl';

const scope = 'pages.Landing.Block5';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Обучение шахматам онлайн – это удобно!',
  },
  point1: {
    id: `${scope}.point1`,
    defaultMessage: `Домашнее задание по шахматам с обратной связью экономит
                    время урока шахмат для изучения нового материала.`,
  },
  point2: {
    id: `${scope}.point2`,
    defaultMessage: 'Уроки шахмат проходят в комфортной домашней обстановке.',
  },
  point3: {
    id: `${scope}.point3`,
    defaultMessage: `24/7 – педагог по шахматам «Chess UP! School» всегда на
                    связи.`,
  },
  point4: {
    id: `${scope}.point4`,
    defaultMessage: `Гибкая система отмены занятий: если нужно скорректировать график, сообщите нам за 8 часов до начала ближайшего урока и мы с радостью подберем вам более подходящее время.`,
  },
});
