import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

import { SCREEN_BREAKPOINTS } from 'constants/theme';
import Benefits from './Benefits';
import messages from './messages';

const Title = styled.h2`
  font-size: 34px;
  text-align: center;
  margin-bottom: 100px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.lg}px) {
    margin-bottom: 80px;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.md}px) {
    margin-right: 80px;
    margin-left: 80px;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    margin-bottom: 50px;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const Section = styled.section`
  margin-bottom: 220px;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    margin-bottom: 90px;
  }
`;

const Block3 = () => (
  <Section>
    <Title>
      <FormattedMessage {...messages.title} />
    </Title>
    <Benefits />
  </Section>
);

export default Block3;
