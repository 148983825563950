import React from 'react';
import { toast } from 'react-toastify';
import { navigate, useLocation } from '@reach/router';
import { SEO } from 'components/SEO';

import { LeadHookModal } from 'containers/Modals/LeadHookModal';
import { LeadModal } from 'containers/Modals/LeadModal';
import { CourseIds } from 'constants/courses';

import Block3 from './Block3';
import Block5 from './Block5';
import { MainBlock } from './MainBlock';
import { BenefitsBlock } from './BenefitsBlock';
import { WorldCoverageBlock } from './WorldCoverageBlock';
import { ActionBlock } from './ActionBlock';
import { TeamBlock } from './TeamBlock';
import { FormBlock } from './FormBlock';
import { PriceBlock } from './PriceBlock';
import { ReviewBlock } from './ReviewBlock';

import { ChessFiguresItem } from './Items/ChessFiguresItem';
import { ChessBoardItem } from './Items/ChessBoardItem';
import { GlassesItem } from './Items/GlasssesItem';
import { ClockItem } from './Items/ClockItem';
import './styles.scss';

const Landing = () => {
  const { search } = useLocation();
  React.useEffect(() => {
    if (search.includes('paymentSuccess')) {
      toast.success('Оплата проведена успешно, благодарим!');
      navigate('/');
    }
  }, []);
  const [isHookTimerStopped, setHookTimerStopped] = React.useState(false);
  const [leadModalOpened, setLeadModalOpened] = React.useState(false);
  const onLeadModalClose = React.useCallback(() => {
    setLeadModalOpened(false);
  }, []);
  const onLeadModalOpen = React.useCallback(() => {
    setHookTimerStopped(true);
    setLeadModalOpened(true);
  }, []);
  const stopHookTimer = React.useCallback(() => {
    setHookTimerStopped(true);
  }, []);

  return (
    <main className="page home-page container">
      <SEO
        landing
        title="Chess UP! School - онлайн-школа шахмат для детей"
        description="Обучаем детей от 6 до 16 лет игре в шахматы по всему миру. Запишитесь на первое бесплатное занятие в онлайн школу шахмат Chess UP. Уроки проводится по скайпу."
        meta={[
          {
            name: `keywords`,
            content: 'онлайн школа шахмат для детей, уроки шахмат онлайн',
          },
        ]}
      />
      <MainBlock onLeadModalOpen={onLeadModalOpen} />
      <ChessFiguresItem />
      <BenefitsBlock />
      <GlassesItem />
      <WorldCoverageBlock />
      <ActionBlock stopHookTimer={stopHookTimer} />
      <Block3 />
      <ClockItem />
      <PriceBlock onLeadModalOpen={onLeadModalOpen} />
      <ChessBoardItem />
      <Block5 />
      <TeamBlock />
      <ReviewBlock />
      <FormBlock stopHookTimer={stopHookTimer} />
      <LeadModal
        courseId={CourseIds.CHESS}
        opened={leadModalOpened}
        onClose={onLeadModalClose}
      />
      <LeadHookModal stopTimer={isHookTimerStopped} />
    </main>
  );
};

export default Landing;
