import { defineMessages } from 'react-intl';

const scope = 'pages.Landing.Block6';

export default defineMessages({
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Благодаря шахматам вы можете обеспечить своему ребёнку прекрасное будущее',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Запишитесь быстрей на \n {free} пробное занятие',
  },
  free: {
    id: `${scope}.free`,
    defaultMessage: 'бесплатное',
  },
});
