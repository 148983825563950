import React from 'react';
import { Form as FormikForm, FormikProps, withFormik } from 'formik';
import { injectIntl } from 'react-intl';
import * as Yup from 'yup';

import { compose } from 'utils/compose';
import { apiCallErrorWithMessage, apiCallSuccessWithMessage } from 'api/utils';
import { apiMessages } from 'api/requests/messages';
import { createFriendLeadRequest } from 'api/requests/createFriendLeadRequest';

import shareImgDesktop from './images/share2.png';
import shareImgMobile from './images/share3.png';
import { PhoneInput } from 'components/PhoneInput';
import { ButtonSpinner } from 'components/ButtonSpinner';
import { FormInput } from 'components/FormInput';
import Button from 'react-bootstrap/Button';
import { ILocation } from 'containers/Location/types';
import { IFormValues } from './types';
import Alert from 'react-bootstrap/Alert';

interface IProps {
  location: ILocation;
  stopHookTimer: () => void;
}

const Form = ({
  isSubmitting,
  touched,
  stopHookTimer,
  status,
}: FormikProps<IFormValues> & IProps) => {
  React.useEffect(() => {
    if (Object.keys(touched).length) {
      stopHookTimer();
    }
  }, [touched]);

  return (
    <FormikForm className="form">
      <img className="arrow-img-desktop" src={shareImgDesktop} alt="стрелка" />
      <img className="arrow-img-mobile" src={shareImgMobile} alt="стрелка" />
      {status && (
        <Alert variant="danger" className="text-center">
          {status}
        </Alert>
      )}
      <FormInput<keyof IFormValues>
        name="name"
        title="Имя"
        placeholder="Введите ваше имя"
      />
      <FormInput<keyof IFormValues>
        name="friendName"
        title="Имя друга"
        placeholder="Введите имя друга"
      />
      <PhoneInput<keyof IFormValues>
        name="phoneNumber"
        title="Номер телефона"
      />
      <Button
        disabled={isSubmitting}
        type="submit"
        className="action-btn btn-shine"
        variant="secondary"
        size="lg"
      >
        {isSubmitting ? (
          <ButtonSpinner />
        ) : (
          <span>
            получить
            <br />2 бесплатных занятия
          </span>
        )}
      </Button>
    </FormikForm>
  );
};

const withForm = withFormik<IProps, IFormValues>({
  mapPropsToValues: () => ({
    name: '',
    friendName: '',
    phoneNumber: '',
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    friendName: Yup.string().required(),
    phoneNumber: Yup.string().required(),
  }),
  handleSubmit: (values, { setSubmitting, setErrors, setStatus, props }) => {
    createFriendLeadRequest({
      name: values.name,
      friendName: values.friendName,
      phoneNumber: values.phoneNumber,
      country: props.location.country,
      city: props.location.city,
      timezone: props.location.timezone,
    })
      .then(
        apiCallSuccessWithMessage(setSubmitting, () => {
          import('sweetalert2').then((Swal) => {
            Swal.default.fire({
              title: 'Заявка отправлена!',
              text: apiMessages.friendLead.success,
              icon: 'success',
              confirmButtonText: 'ОК',
            });
          });
        }),
      )
      .catch(apiCallErrorWithMessage(setSubmitting, setErrors, setStatus));
  },
});

export default compose(injectIntl, withForm)(Form);
