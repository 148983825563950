import React from 'react';
import styled from '@emotion/styled';

import Image from 'components/Image';
import shareImg from 'assets/images/share.png';
import { SCREEN_BREAKPOINTS } from 'constants/theme';

import Button from 'react-bootstrap/Button';

const ActionBlock = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.5rem;
  margin-top: 1rem;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    width: 220px;
    margin-bottom: 20px;
    margin-top: 0;
  }
`;

const ShareImg = styled(Image)`
  margin-bottom: 1.5rem;
  margin-right: 2rem;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.md}px) {
    margin-right: 1.5rem;
    height: 39px;
  }
`;

const ActionText = styled.div`
  max-width: 220px;
  font-size: 18px;
  line-height: 24px;

  b {
    color: #e02053;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.sm}px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  @media all and (max-width: ${SCREEN_BREAKPOINTS.xxlg}px) {
    width: 620px;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.lg}px) {
    width: 600px;
  }

  @media all and (max-width: ${SCREEN_BREAKPOINTS.md}px) {
    width: auto;
    justify-content: center;
  }
`;

const Bold = styled.b`
  font-weight: 800;
`;

const CallToAction = ({ onLeadModalOpen }) => (
  <Wrapper>
    <ActionBlock>
      <ShareImg src={shareImg} alt="поделиться" />
      <ActionText>
        Запишитесь на <Bold className="text-uppercase">бесплатное</Bold>
        <br />
        пробное занятие
      </ActionText>
    </ActionBlock>
    <Button
      size="lg"
      className="action-btn btn-shine"
      variant="secondary"
      onClick={onLeadModalOpen}
    >
      Записаться
    </Button>
  </Wrapper>
);

export default CallToAction;
