import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const MainProposition = () => (
  <React.Fragment>
    <h1 className="title">
      <b>Научим</b> ваших <b>детей</b> игре в <b>шахматы</b> не выходя из дома
    </h1>
    <p className="subtitle">
      <FormattedMessage {...messages.mainProposition.subtitle} />
    </p>
  </React.Fragment>
);
