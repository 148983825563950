import React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { graphql, StaticQuery } from 'gatsby';

export const ActionImg = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          coverImage: file(
            relativePath: {
              eq: "screens/Home/ActionBlock/images/action-box.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                blurredOptions: { width: 200 }
                webpOptions: { quality: 100 }
              )
            }
          }
        }
      `}
      render={(data) => {
        const imageData = getImage(data.coverImage) as IGatsbyImageData;
        return (
          <div className="action-img-container">
            <GatsbyImage image={imageData} alt="пакет уроков" loading="lazy" />
          </div>
        );
      }}
    />
  );
};
