import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { IReview } from 'types/review';
import './styles.scss';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  data: IReview;
}

export const ReviewModal = ({ isOpen, onClose, data }: IProps) => {
  return (
    <Modal
      className="review-modal"
      show={isOpen}
      onHide={onClose}
      backdropClassName="backdrop-light"
      centered
    >
      <Modal.Body>
        <button onClick={onClose} className="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <Modal.Title className="title">{data.name}</Modal.Title>
        <a
          className="review-link"
          href={data.socialLink}
          target="_blank"
          rel="noreferrer noopener"
        >
          {data.socialLink}
        </a>
        <div className="text">{data.text}</div>
      </Modal.Body>
    </Modal>
  );
};
