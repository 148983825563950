import { defineMessages } from 'react-intl';

const scope = 'pages.Landing.Block2';

export default {
  ...defineMessages({
    title: {
      id: `${scope}.title`,
      defaultMessage: 'Почему именно шахматы?',
    },
  }),
  reason1: defineMessages({
    title: {
      id: `${scope}.reason1.title`,
      defaultMessage: 'Развивают логическое мышление',
    },
    text: {
      id: `${scope}.reason1.text`,
      defaultMessage:
        'За время партии маленькому шахматисту не раз придется анализировать ситуацию на доске и делать выводы. Со временем он сам себе усложнит задачу: будет пробовать взглянуть на ход игры глазами соперника, просчитать его возможные ответные ходы. Такой логический анализ вдвойне полезен, так как подвергается немедленной практической проверке. В свою очередь это побуждает ребенка анализировать свои ошибки. Согласитесь, подобные навыки не раз пригодятся ему и в школе, и во взрослой жизни.',
    },
  }),
  reason2: defineMessages({
    title: {
      id: `${scope}.reason2.title`,
      defaultMessage: 'Тренируют память',
    },
    text: {
      id: `${scope}.reason2.text`,
      defaultMessage:
        'Шахматы прекрасно тренируют память и развивают внимание. Это не только запоминание вариантов и схем игры, но и применение их на практике. Таким образом, шахматы не только помогут ребенку научиться держать в поле своего внимания множество деталей одновременно, но и научат оперативно "извлекать" и использовать полученные прежде знания.',
    },
  }),
  reason3: defineMessages({
    title: {
      id: `${scope}.reason3.title`,
      defaultMessage: 'Учат выигрывать и проигрывать',
    },
    text: {
      id: `${scope}.reason3.text`,
      defaultMessage: `Конечно, всем нравится побеждать, но в равной степени важно и
                научиться принимать поражения. Как говорят, иногда вы даете
                урок, а иногда вам дают урок! Умение побеждать - важная черта
                характера, которую можно приобрести благодаря шахматам.`,
    },
  }),
  reason4: defineMessages({
    title: {
      id: `${scope}.reason4.title`,
      defaultMessage: 'Развивают усидчивость и концентрацию внимания',
    },
    text: {
      id: `${scope}.reason4.text`,
      defaultMessage:
        'Вашему ребенку потребуется усвоить много новой информации: расположение фигур на доске, правила игры, множество вариантов и комбинаций ходов. Без усидчивости здесь никуда... Выходит, что со временем способность долго удерживать внимание на определенном процессе станет привычной, что безусловно скажется полезным и в других областях.',
    },
  }),
};
