import React from 'react';
import { Form as FormikForm, FormikProps, withFormik } from 'formik';
import { FormInput } from 'components/FormInput';
import { PhoneInput } from 'components/PhoneInput';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import { ILocation } from 'containers/Location/types';
import { CourseIds } from 'constants/courses';
import { createLeadRequest } from 'api/requests/createLeadRequest';
import { apiCallErrorWithMessage, apiCallSuccessWithMessage } from 'api/utils';
import { ButtonSpinner } from 'components/ButtonSpinner';
import { IFormValues } from './types';
import Alert from 'react-bootstrap/Alert';
import { apiMessages } from 'api/requests/messages';

interface IProps {
  location: ILocation;
  onSuccess: () => void;
}

const FormInner = ({ isSubmitting, status }: FormikProps<IFormValues>) => (
  <FormikForm className="lead-hook-modal-form">
    {status && (
      <Alert variant="danger" className="text-center">
        {status}
      </Alert>
    )}
    <FormInput<keyof IFormValues>
      name="name"
      title="Имя"
      placeholder="Введите ваше имя"
    />
    <FormInput<keyof IFormValues>
      type="email"
      name="email"
      title="Email"
      placeholder="Введите ваш email"
    />
    <PhoneInput<keyof IFormValues> name="phoneNumber" title="Номер телефона" />
    <Button
      disabled={isSubmitting}
      type="submit"
      className="action-btn"
      variant="secondary"
      size="lg"
    >
      {isSubmitting ? <ButtonSpinner /> : `Записаться`}
    </Button>
  </FormikForm>
);

const withForm = withFormik<IProps, IFormValues>({
  mapPropsToValues: () => ({
    name: '',
    email: '',
    phoneNumber: '',
    courseId: CourseIds.CHESS,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    phoneNumber: Yup.string().required(),
    courseId: Yup.mixed(),
  }),
  handleSubmit: (values, { setSubmitting, setErrors, setStatus, props }) => {
    createLeadRequest({
      courseId: values.courseId,
      email: values.email,
      name: values.name,
      phoneNumber: values.phoneNumber,
      country: props.location.country,
      city: props.location.city,
      timezone: props.location.timezone,
    })
      .then(
        apiCallSuccessWithMessage(setSubmitting, () => {
          import('sweetalert2').then((Swal) => {
            Swal.default.fire({
              title: 'Заявка отправлена!',
              text: apiMessages.lead.success,
              icon: 'success',
              confirmButtonText: 'ОК',
            });
          });
          props.onSuccess();
        }),
      )
      .catch(apiCallErrorWithMessage(setSubmitting, setErrors, setStatus));
  },
});

export const Form = withForm(FormInner);
