import { IReview, SocialType } from 'types/review';
import { CourseIds } from './courses';
import chess1Img from 'assets/images/reviews/chess1.png';
import chess2Img from 'assets/images/reviews/chess2.png';
import chess3Img from 'assets/images/reviews/chess3.png';

export const reviews: IReview[] = [
  {
    courseId: CourseIds.CHESS,
    name: 'Юлия',
    social: SocialType.INSTAGRAM,
    socialLink: 'https://instagram.com/khadasevichiuliia',
    text: `Здравствуйте!\n
Наш сын восьми лет начал заниматься шахматами по скайпу с нуля. Так как он новичок, необходимы ответы на многие вопросы и просто смотреть видео в YouTube и по ним заниматься совсем недостаточно. Ездить же в шахматный клуб сам он пока не может, возить же его не всегда получается из-за занятости нас взрослых. Нам предложили провести пробный урок. Он показал, что такая форма обучения ничуть не уступает занятиям в клубе, а даже имеет ряд преимуществ. Информация поступает в высокой концентрации без воды, учитель терпеливый, легко и интересно объясняет новую тему. Урок пролетает быстро и динамично в постоянном диалоге. А главное, сыну не надо никуда ехать, мы не зависим от погоды, выбираем удобное для нас время, занятия проходят в комфортной домашней обстановке! Спасибо за предоставление возможности такого обучения!\n
Довольны мы, и главное счастлив ребёнок! Он уже ставит перед собой высокие цели, а значит впереди большие победы!`,
    photo: chess1Img,
  },
  {
    courseId: CourseIds.CHESS,
    name: 'Надежда',
    social: SocialType.INSTAGRAM,
    socialLink: 'https://instagram.com/zagorskaianadezhda',
    text: `В современном ритме, жизни занятия которые проходят online - это просто спасение для занятых родителей!!! Ещё в начале учебного года моя дочь ОЧЕНЬ хотела заниматься шахматами, но то расписание, которое ей предлагали в различных шахмытных школах, нам совсем никак не подошло. Дочь конечно расстроилась, но согласилась подождать и начать заниматься через год. Меня же постоянно мучила совесть, что ребенок посещает навязанные мной занятия и не может заниматься тем, чем ей самой очень хочется.\n
И вот, однажды, в Инстаграмме я наткнулась на эту школу! Позвонила и записалась на пробное занятие. После пробного занятия у моей дочери просто горели глаза, я поняла, что это как раз то, что мне надо.
Для себя я нашла в этой ситуации много плюсов:
1. Экономия своего времени, никуда не надо ехать, во время занятия ребенка я занимаюсь своими делами.
2. Так как ехать никуда не надо - это ещё и экономия денег на топливо/такси/автобус.
3. Занятия индивуальные, поэтому если не получается заниматься по расписанию, то по согласованию,  занятие можно перенести, и периодичность занятий можно выбрать любую.
4. Я всегда могу проследить за ходом занятий своего ребенка и вижу насколько она понимает изучаемый материал.
5. Моей дочери досталась хорошая преподаватель - Каролина, дочке интересно с ней заниматься. Каролина нашла подход к моему ребенку, что тоже очень важно в процессе обучения!!!
P.S. Занятия в данной школе для меня просто спасение: ребенок занят обучением, а я в это время свободна.
ИСКРЕННЕ РЕКОМЕНДУЮ ВСЕМ`,
    photo: chess2Img,
  },
  {
    courseId: CourseIds.CHESS,
    name: 'Зинаида',
    social: SocialType.INSTAGRAM,
    socialLink: 'https://instagram.com/zinaidasytik',
    text: `Сыну 8 лет.Давно хотел научиться играть в шахматы. И онлайн школа стала для нас просто спасением, так как живём за городом, возможности возить на занятия нет. Попробовали— понравилось! Занятия проходят интересно и очень продуктивно. Тренер быстро нашел подход к ребёнку, объясняет понятно, все наглядно демонстрирует.
Думаю в современном мире, когда времени катастрофически не хватает и хочется многое успеть, онлайн обучение как раз то что нужно!
Плюсы: интересно, доступно, индивидуальный подход к ребёнку, не надо тратить время на дорогу.
Минусы: хочется практики! Чтобы научиться—нужно играть! На одной теории далеко не уедешь!
Что делать, если в ближайшем окружении нет людей, с которыми можно поиграть?!!!\n
PS. Идея открыть онлайн школу шахмат просто бомбическая!
Желаю организаторам успехов! Пусть у Вас все получится!
И ещё маленькая ремарка: когда первый раз оплачиваешь, немного страшновато— вдруг очередной обман....мне кажется для спокойствия клиентов нужен договор, где будут оговорены все условия, а оплата будет автоматически подтверждать, что клиент согласен с условиями....\n
Ну вот, как—то так!`,
    photo: chess3Img,
  },
];
