import React from 'react';

import { MainProposition } from './MainProposition';
import { Benefits } from './Benefits';
import { BackgroundImg } from './BackgroundImg';
import CallToAction from './CallToAction';

interface IProps {
  onLeadModalOpen: () => void;
}

export const MainBlock = ({ onLeadModalOpen }: IProps) => (
  <section className="main-block">
    <div className="main-block-content">
      <MainProposition />
      <Benefits />
      <CallToAction onLeadModalOpen={onLeadModalOpen} />
    </div>
    <BackgroundImg />
  </section>
);
