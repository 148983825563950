import sergeyPhoto from './images/photo_sergey.png';
import alexeyPhoto from './images/photo_alexey.png';
// import alexanderPhoto from './images/photo_aleksandr.png';
import karolinaPhoto from './images/photo_karolina.png';
// import polinaPhoto from './images/photo_polina.png';
import romanPhoto from './images/photo_roman.png';
import { ITeamMember } from 'types/teamMember';

export const members: ITeamMember[] = [
  {
    name: 'Роман',
    text:
      'Меня зовут Роман Иванович и я детский тренер по шахматам. В преподавании я совмещаю две моих страсти, а именно шахматы и передача знаний другим. С раннего детства занимался шахматами и обучение носило академический характер, что имело как свои плюсы так и минусы.\n' +
      '\n' +
      'На своих занятиях стараюсь в первую очередь заинтересовать ребенка шахматами, чтобы он хотел еще и вне занятия уделять им время. Проанализировав весь свой опыт внес поправки в тот стиль преподнесения информации, который использовался в подготовке меня как шахматиста, наложил специфику онлайна и вуаля, вижу результаты и горящие глаза у своих подопечных))\n' +
      '\n' +
      'Получаю огромное удовлетворение видя как ученики растут под моим руководством, интересуются и горят желанием узнавать все больше и больше, да и меня самого мотивируют результаты детей на возвращение на профессиональные турниры) В данный момент оканчиваю факультет философии БГУ.',
    photo: romanPhoto,
  },
  // {
  //   name: 'Полина',
  //   text:
  //     'Добрый день! Меня зовут Полина! Мне 38 лет. У меня три высших образования. Растёт сын, который научил меня работать с детьми и показать им, как интересен и увлекателен МИР ШАХМАТ.\n' +
  //     '\n' +
  //     'Уже более 5и лет работаю с детьми дошкольного и младшего школьного возраста. Для своих воспитанников я Друг и зовут меня Полина, с которым можно чудесно поиграть и узнать все хитрости шахматной игры.\n' +
  //     '\n' +
  //     'Игра - вот что нужно и обожают дети, все обучение для младших ребят строится именно на игре. Играя, я делюсь опытом, знаниями и невероятной энергией с каждым учеником, верой в победу не только на шахматной доске, но и в жизни! \n' +
  //     '\n' +
  //     'Знаю, что не каждый тренер может работать с детьми дошкольного и младшего школьного возраста. А моим главным козырем является - терпение, позитив, выдержка, ответственность, креативность и главное ЛЮБОВЬ к детям. \n' +
  //     '\n' +
  //     'Мои увлечения. Я обожаю свою работу, семью, путешествия, спорт и обучение. Учусь всегда, всему и везде. Мой девиз по жизни - только вперёд!',
  //   photo: polinaPhoto,
  // },
  {
    name: 'Сергей',
    text:
      'Меня зовут Сергей. Моим первым учителем шахмат был отец, в возрасте 7 лет я начал заниматься в шахматном клубе. Достаточно быстро мне удалось добиться звания КМС. Уверен, что именно шахматы помогли мне получить высшее техническое образование. В дальнейшем эта увлекательная игра прочно вошла в мою жизнь и осталась в ней навсегда. \n' +
      '\n' +
      'Чем же увлекательны шахматы, и почему эта игра стала моей любимой? 12-й чемпион мира А. Карпов сказал: «Шахматы – это удивительная игра, содержащая в себе науку, искусство, спорт». Я полностью согласен с этим мнением, т. к. убедился в этом сам. \n' +
      'Шахматы – это мое самое главное увлечение и хобби. Считаю, что увлечь шахматами можно любого ребенка, только необходимо найти к нему подход. Лучшая награда для меня – это видеть успехи своих учеников. \n' +
      '\n' +
      'Готов заниматься с ребятами как для достижения спортивного результата, так и просто для повышения уровня интеллекта. ',
    photo: sergeyPhoto,
  },
  // {
  //   name: 'Александр',
  //   text:
  //     'Добрый день! Меня зовут Александр, мне 32 года. Имею высшее образование, закончил БГУ по специальности научно-педагогическая деятельность.\n' +
  //     '\n' +
  //     'Шахматы были моим хобби со школьных лет, выполнил норму кандидата в мастера спорта.  С 2007 года имею опыт проведения занятий по шахматам, судейства шахматных турниров, часто вывожу своих учеников на республиканские и международные соревнования. Занимаюсь с детьми от 6 лет и старше.\n' +
  //     '\n' +
  //     'Сейчас во многом современное обучение детей сводится к игровым формам чтобы их было проще увлечь процессом. Бывая в других странах часто стараюсь совместить поездку с участием в шахматном турнире, так как ещё один плюс шахмат заключается в том, что они являются универсальным средством общения для людей разных национальностей.',
  //   photo: alexanderPhoto,
  // },
  {
    name: 'Алексей',
    text:
      'Кандидат в мастера спорта по шахматам.\n' +
      '\n' +
      'Окончил БГУФК, тренер по шахматам. Являюсь участником в городских, республиканских и международных соревнований.\n' +
      '\n' +
      'Более 4 лет работаю с детьми дошкольного и младшего школьного возраста по \n' +
      'таким направлениям как шахматы и шашки.\n',
    photo: alexeyPhoto,
  },
  {
    name: 'Каролина',
    text:
      'Всем привет! \n' +
      'Меня зовут Каролина. \n' +
      'Я работаю шахматным тренером с сентября 2017 г. На данный момент получаю высшее образование в БГУКИ.\n' +
      'Почему именно шахматы?\n' +
      'Шахматы - это интеллектуальное искусство. \n' +
      'Шахматы учат принимать решение, концентрировать внимание, думать на опережение и предвидеть результаты своих шагов.',
    photo: karolinaPhoto,
  },
];
