import React from 'react';
import { LocationContext } from 'containers/Location/LocationContext';
import Form from './Form';
import { ActionImg } from './ActionImg';

interface IProps {
  stopHookTimer: () => void;
}

export const ActionBlock = ({ stopHookTimer }: IProps) => {
  const location = React.useContext(LocationContext);
  return (
    <section className="action-block">
      <div className="action-content">
        <h5 className="title">
          <b>акция</b> | приведите друга и получите по <b>2 бесплатных</b>{' '}
          занятия вам и другу
        </h5>
        <Form stopHookTimer={stopHookTimer} location={location} />
      </div>
      <ActionImg />
    </section>
  );
};
