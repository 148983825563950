import React from 'react';
import { FormattedMessage } from 'react-intl';

import boyBroadSmileImg from 'assets/images/boy-broad-smile.png';
import likeImg from 'assets/images/like.png';
import calendarImg from 'assets/images/calendar.png';

import messages from './messages';

export const Benefits = () => (
  <div className="benefits">
    <div className="benefit-item">
      <img src={boyBroadSmileImg} alt="лицо" />
      <p>
        <FormattedMessage {...messages.benefits.first} />
      </p>
    </div>
    <div className="benefit-item">
      <img src={likeImg} alt="палец вверх" />
      <p>
        <FormattedMessage {...messages.benefits.second} />
      </p>
    </div>
    <div className="benefit-item">
      <img src={calendarImg} alt="календарь" />
      <p>
        <FormattedMessage {...messages.benefits.third} />
      </p>
    </div>
  </div>
);
