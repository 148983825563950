import { keyframes } from '@emotion/core';

export const Pop = keyframes`
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
`;
